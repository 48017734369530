import React from 'react'

import { Head } from '../../components/Head'
import { NotFoundExample } from '../../examples/html/pages/404Example'

const NotFoundPage = () => (
  <>
    <Head title="Sivua ei löydy" />
    <NotFoundExample />
  </>
)

export default NotFoundPage
