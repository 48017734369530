import React from 'react'

import {
  Col,
  Container,
  Heading,
  Image,
  Link,
  Main,
  Paragraph,
  Row,
  Spacer,
  Wrapper
} from '@te-digi/styleguide'

import fourZeroFourSvg from '@te-digi/styleguide/assets/404.svg'

import { LinkMock } from '../../../components/LinkMock'
import { SkipLinkHTMLExample } from '../../../pages/navigointi/skiplink'

import { HeaderHTMLExample } from '../HeaderHTMLExample'
import { FooterHTMLExample } from '../FooterHTMLExample'

const NotFoundExample = () => (
  <>
    {SkipLinkHTMLExample()}
    <Wrapper>
      {HeaderHTMLExample({ activePrimaryItem: '' })}
      <Main>
        <Spacer
          marginTop="layout-lg"
          marginBottom="layout-xxl"
        >
          <Container>
            <Row alignItems="center">
              <Col md={5}>
                <Row>
                  <Col
                    xs={{
                      offset: 3,
                      size: 6
                    }}
                    md={{
                      offset: 2,
                      size: 8
                    }}
                    lg={{
                      offset: 2,
                      size: 10
                    }}
                  >
                    <Image
                      fluid
                      alt=""
                      src={fourZeroFourSvg}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={7}>
                <Heading
                  align="center"
                  level={1}
                  size={2}
                >
                  Voi ei! Etsimääsi sivua ei löytynyt.
                </Heading>
                <Paragraph align="center">
                  Olemme pahoillamme. Sivua, johon yritit päästä, ei syystä tai
                  toisesta löytynyt.
                </Paragraph>
                <Paragraph align="center">
                  Toivottavasti löydät etsimäsi{' '}
                  <Link as={LinkMock}>Työmarkkinatorin etusivulta</Link>.
                </Paragraph>
              </Col>
            </Row>
          </Container>
        </Spacer>
      </Main>
      <FooterHTMLExample />
    </Wrapper>
  </>
)

export { NotFoundExample }
